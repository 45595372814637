var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-lg-5",attrs:{"fluid":""}},[_c('app-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],attrs:{"modal":_vm.modal},on:{"submit-modal":_vm.submitModal,"text-changed":_vm.progressChanged}}),_c('preview-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],attrs:{"modal":_vm.previewModal}}),_c('table-filter',{attrs:{"items":_vm.yearOptions,"label":_vm.$t('models.goal.attributes.year')},on:{"filterTable":_vm.filterYear}}),_c('v-card',{staticClass:"rounded-card",attrs:{"flat":"","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.visibleHeaders,"items":_vm.goals.items,"options":_vm.options,"server-items-length":_vm.goals.count,"loading":_vm.loading,"no-data-text":_vm.$t('messages.emptyState', { entity: _vm.$tc('models.goal.entity') }),"no-results-text":_vm.$t('messages.emptyState', { entity: _vm.$tc('models.goal.entity') })},on:{"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"item.isPrivate",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.isPrivate? 'mdi-lock' : 'mdi-lock-open'))])]}},{key:"item.assigneeId",fn:function(ref){
var item = ref.item;
return [(item.assignee)?_c('v-list-item',[_c('v-list-item-avatar',[_c('app-avatar',{staticClass:"no-padding",attrs:{"url":_vm.getFileUrl(item.assignee.avatar),"alternative":item.assignee.initials,"size":"xsmall"}})],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(item.assignee.name)}})],1):_vm._e()]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.startDate, 'DD MMM YYYY').format('ddd, DD MMM'))+" - "+_vm._s(_vm.moment(item.endDate, 'DD MMM YYYY').format('ddd, DD MMM')))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [(item.duration)?[_vm._v(" "+_vm._s(item.duration.convention)+" ("+_vm._s(item.duration.hours)+_vm._s(_vm.$t('models.goal.attributes.hours'))+") ")]:_vm._e()]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t(("models.goal.attributes.states." + (item.state))))+": "+_vm._s(item.progress)+"% ")]),_c('v-progress-linear',{attrs:{"rounded":"","value":item.progress}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"small chip-width"},[_c('v-icon',{attrs:{"left":"","small":"","color":_vm.statuses && item.status ? _vm.statuses[item.status].color : 'seconadry'}},[_vm._v(" "+_vm._s(item.status ? _vm.statuses[item.status].icon : '')+" ")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t(("models.goal.attributes.statuses." + (item.status)))))])],1)]}},{key:"item.reviewerId",fn:function(ref){
var item = ref.item;
return [(item.reviewer)?_c('v-list-item',[_c('v-list-item-avatar',[_c('app-avatar',{staticClass:"no-padding",attrs:{"url":_vm.getFileUrl(item.reviewer.avatar),"alternative":item.reviewer.initials,"size":"xsmall"}})],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(item.reviewer.name)}})],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.goalStatus.PENDING)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editGoal(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.tableName === _vm.goalTables.MANAGER_PENDING_REQUESTS)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.manageGoalRequest(_vm.goalStatus.APPROVED , item.id, _vm.goals.items.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1):_vm._e(),(_vm.tableName === _vm.goalTables.MANAGER_PENDING_REQUESTS)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.manageGoalRequest(_vm.goalStatus.REJECTED,  item.id, _vm.goals.items.indexOf(item))}}},[_c('v-icon',[_vm._v("mdi-thumb-down-outline")])],1):_vm._e(),(_vm.tableName === _vm.goalTables.USER_GOALS && item.status === _vm.goalStatus.PENDING)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteGoal(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e(),(_vm.tableName === _vm.goalTables.OTHER_GOALS ||
            (_vm.tableName === _vm.goalTables.USER_GOALS && +item.progress === 100))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.previewGoal(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),(_vm.tableName === _vm.goalTables.USER_GOALS && item.status === _vm.goalStatus.APPROVED && item.progress < 100)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.updateProgress(item)}}},[_c('v-icon',[_vm._v("mdi-progress-check")])],1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }