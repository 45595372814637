<template>
  <v-row justify="center">
    <v-dialog v-model="modal.show" v-if="modal.goal" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ modal.goal.title }}</v-card-title>
        <v-card-subtitle class="mt-1">{{ modal.goal.description }}</v-card-subtitle>
        <v-card-text>
          <div class="row mx-1">
            <p class="col-6 text-start pa-0 my-0">{{ $t(`models.goal.attributes.states.${modal.goal.state}`) }}</p>
            <p class="col-6 text-end pa-0 my-0">{{ modal.goal.progress }}%</p>
            <v-progress-linear rounded height="7" :value="modal.goal.progress"></v-progress-linear>
            <div v-if="modal.goal.supportingDocument" class="mt-5 mb-2">
              <v-btn v-if="modal.goal.assignee.id === this.currentUser.id"
                text small color="text" class="px-0"
                @click="downloadGoalAttachment({
                  filename: modal.goal.supportingDocumentName,
                  url: getFileUrl(modal.goal.supportingDocument)})">
                <v-icon class="pl-0 pr-1">mdi-download-outline</v-icon>
                <span>{{ modal.goal.supportingDocumentName }}</span>
              </v-btn>
              <template v-else>
                <v-icon class="pl-0 pr-1">mdi-attachment</v-icon>
                <span>{{ modal.goal.supportingDocumentName }}</span>
              </template>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'previewModal',
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  computed: mapGetters(['currentUser']),
  methods: mapActions(['downloadGoalAttachment'])
};
</script>
