<template>
  <v-container fluid class="px-lg-5">
    <app-modal v-show="isModalVisible" :modal="modal" @submit-modal="submitModal" @text-changed="progressChanged"/>
    <preview-modal v-show="isModalVisible" :modal="previewModal"/>
    <table-filter @filterTable="filterYear" :items="yearOptions" :label="$t('models.goal.attributes.year')"/>
    <v-card class="rounded-card" flat outlined>
      <v-data-table
        :headers="visibleHeaders"
        :items="goals.items"
        :options.sync="options"
        :server-items-length="goals.count"
        :loading="loading"
        :no-data-text="$t('messages.emptyState', { entity: $tc('models.goal.entity') })"
        :no-results-text="$t('messages.emptyState', { entity: $tc('models.goal.entity') })"
        @update:options="updateOptions">
        <template v-slot:[`item.isPrivate`]="{ item }">
          <v-icon small>{{ item.isPrivate? 'mdi-lock' : 'mdi-lock-open' }}</v-icon>
        </template>
        <template v-slot:[`item.assigneeId`]="{ item }">
          <v-list-item v-if="item.assignee">
            <v-list-item-avatar>
              <app-avatar :url="getFileUrl(item.assignee.avatar)" :alternative="item.assignee.initials"
                size="xsmall" class="no-padding"/>
            </v-list-item-avatar>
            <v-list-item-content v-text="item.assignee.name"/>
          </v-list-item>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          <span>{{ moment(item.startDate, 'DD MMM YYYY').format('ddd, DD MMM') }} -
           {{ moment(item.endDate, 'DD MMM YYYY').format('ddd, DD MMM') }}</span>
        </template>
        <template v-slot:[`item.duration`]="{ item }">
          <template v-if="item.duration">
            {{ item.duration.convention }} ({{ item.duration.hours }}{{ $t('models.goal.attributes.hours') }})
          </template>
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          <div class="caption">
            {{$t(`models.goal.attributes.states.${item.state}`)}}: {{ item.progress }}%
          </div>
          <v-progress-linear rounded :value="item.progress"></v-progress-linear>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip class="small chip-width">
            <v-icon left small :color="statuses && item.status ? statuses[item.status].color : 'seconadry'">
              {{ item.status ? statuses[item.status].icon : '' }}
            </v-icon>
            <span class="caption">{{ $t(`models.goal.attributes.statuses.${item.status}`) }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.reviewerId`]="{ item }">
          <v-list-item v-if="item.reviewer">
            <v-list-item-avatar>
              <app-avatar :url="getFileUrl(item.reviewer.avatar)" :alternative="item.reviewer.initials"
                size="xsmall" class="no-padding"/>
            </v-list-item-avatar>
            <v-list-item-content v-text="item.reviewer.name" />
          </v-list-item>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small
           @click="editGoal(item.id)"
           v-if="item.status === goalStatus.PENDING">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon small @click="manageGoalRequest(goalStatus.APPROVED , item.id, goals.items.indexOf(item))"
            v-if="tableName === goalTables.MANAGER_PENDING_REQUESTS">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
          <v-btn icon small @click="manageGoalRequest(goalStatus.REJECTED,  item.id, goals.items.indexOf(item))"
            v-if="tableName === goalTables.MANAGER_PENDING_REQUESTS">
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
          <v-btn icon small
            @click="deleteGoal(item)"
            v-if="tableName === goalTables.USER_GOALS && item.status === goalStatus.PENDING">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn icon small @click="previewGoal(item)"
            v-if="tableName === goalTables.OTHER_GOALS ||
              (tableName === goalTables.USER_GOALS && +item.progress === 100)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn icon small @click="updateProgress(item)"
            v-if="tableName === goalTables.USER_GOALS && item.status === goalStatus.APPROVED && item.progress < 100">
            <v-icon>mdi-progress-check</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import {mapGetters, mapActions} from 'vuex';
import previewModal from 'components/goals/PreviewModal.vue';
import {
  goalStatus, goalState, goalTables
} from 'constants';

export default {
  name: 'goalsTable',
  components: {previewModal},
  props: {
    goals: {
      type: Object,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      moment,
      goalStatus,
      goalState,
      goalTables,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false,
        filterBy: {year: moment().format('YYYY')}
      },
      statuses: {
        pending: {
          icon: 'mdi-timer-sand',
          color: 'info'
        },
        approved: {
          icon: 'mdi-thumb-up-outline',
          color: 'success'
        },
        rejected: {
          icon: 'mdi-thumb-down-outline',
          color: 'danger'
        }
      },
      isModalVisible: false,
      modal: {
        show: false,
        id: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          description: {
            type: 'textarea',
            key: 'description',
            value: '',
            outlined: true,
            dense: true,
            label: this.$t('models.goal.attributes.description'),
            classes: 'col-12 py-0',
            serverErrors: []
          },
          progress: {
            type: 'slider',
            key: 'progress',
            value: '',
            label: this.$t('models.goal.attributes.progress'),
            trackable: true,
            suffix: '%',
            classes: 'col-12 py-0',
            serverErrors: []
          },
          supportingDocument: {
            type: 'file',
            showImage: true,
            key: 'supportingDocument',
            value: null,
            pastFile: '',
            clearable: true,
            showSize: true,
            rules: [{
              name: 'fileSize',
              size: 2000000
            }],
            accept: 'image/*, .pdf',
            icon: 'mdi-attachment',
            label: this.$t('models.goal.attributes.supportingDocument'),
            classes: 'col-12 py-0',
            hidden: true,
            serverErrors: []
          }
        }
      },
      previewModal: {
        show: false,
        goal: null
      }
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    headers() {
      return [
        {
          text: '',
          value: 'isPrivate',
          align: 'center',
          show: true
        },
        {
          text: this.$t('models.goal.attributes.assignee'),
          value: 'assigneeId',
          align: 'center',
          show: this.tableName !== this.goalTables.USER_GOALS
        },
        {
          text: this.$t('models.goal.attributes.title'),
          value: 'title',
          align: 'center',
          show: true
        },
        {
          text: this.$t('models.goal.attributes.duration'),
          value: 'duration',
          align: 'center',
          show: true
        },
        {
          text: this.$t('models.goal.attributes.dates'),
          value: 'startDate',
          align: 'center',
          show: true
        },
        {
          text: this.$t('models.goal.attributes.progress'),
          value: 'progress',
          align: 'center',
          show: this.tableName !== this.goalTables.MANAGER_PENDING_REQUESTS
        },
        {
          text: this.$t('models.goal.attributes.status'),
          value: 'status',
          align: 'center',
          show: ![this.goalTables.MANAGER_PENDING_REQUESTS, this.goalTables.OTHER_GOALS].includes(this.tableName)
        },
        {
          text: this.$t('models.goal.attributes.reviewer'),
          value: 'reviewerId',
          align: 'center',
          show: ![this.goalTables.MANAGER_PENDING_REQUESTS, this.goalTables.OTHER_GOALS].includes(this.tableName)
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          align: 'center',
          show: true
        }
      ];
    },
    visibleHeaders() {
      return this.headers.filter((header) => header.show);
    },
    yearOptions() {
      let currentYear = this.moment().year();
      return Array(3).fill().map(() => currentYear--);
    }
  },
  methods: {
    ...mapActions(['destroyGoal', 'manageGoal', 'updateGoalProgress']),
    updateOptions(options) {
      this.$emit('updateOptions', options);
    },
    filterYear(year) {
      this.options.filterBy.year = year;
      this.updateOptions(this.options);
    },
    editGoal(id) {
      this.$router.push({
        name: 'EditGoal',
        params: {id: id}
      });
    },
    manageGoalRequest(status, id, index) {
      const data = {
        id: id,
        status: status,
        index: index
      };
      this.$emit('manageGoalRequest', data);
    },
    deleteGoal(goal) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: goal.title}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyGoal(goal.id).then(() => {
            this.updateOptions(this.options);
          });
        }
      });
    },
    previewGoal(goal) {
      this.previewModal.goal = goal;
      this.previewModal.show = true;
    },
    updateProgress(goal) {
      this.modal.resetForm = true;
      this.goal = goal;
      this.modal.fields.supportingDocument.hidden = true;
      this.modal.fields.description.readonly = true;

      this._.forEach(['title', 'id'], (key) => this.modal[key] = goal[key]);
      this._.forEach(['progress', 'description'], (fieldKey) => this.modal.fields[fieldKey].value = goal[fieldKey]);

      this.modal.fields.description.hidden = !goal.description;
      this.modal.show = true;
    },
    progressChanged($event) {
      if ($event.value === 100 && this.modal.fields.supportingDocument.hidden) {
        this.modal.fields.supportingDocument.hidden = false;
      } else if ($event.value < 100 && !this.modal.fields.supportingDocument.hidden) {
        this.modal.fields.supportingDocument.value = null;
        this.modal.fields.supportingDocument.hidden = true;
      }
    },
    submitModal(data) {
      const progressData = new FormData();
      progressData.append('progress', data.progress);
      if (data.supporting_document) {
        progressData.append('supporting_document', data.supporting_document[0]);
      }
      this.updateGoalProgress({form: progressData, id: this.modal.id}).then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
          this.goal = null;
          this.updateOptions(this.options);
        } else {
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.align-middle-vertical {
  line-height: 35px;
}
td, tr {
  vertical-align: middle;
}
.chip-width {
  width: 92px;
}
</style>
