<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" disable-button />
    <div class="row">
      <goals-table :tableName="goalTables.OTHER_GOALS"
        :goals="goals"
        :loading="loading"
        @updateOptions="updateOptions">
      </goals-table>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import goalsTable from 'components/goals/Table.vue';
import {goalTables, goalStatus} from 'constants';

export default {
  name: 'OtherGoals',
  components: {goalsTable},
  data() {
    return {
      title: {
        text: this.$t('layout.mainMenu.otherGoals'),
        icon: 'mdi-clipboard-list-outline'
      },
      breadcrumbs: [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'}
      },
      {
        text: this.$t('layout.mainMenu.otherGoals'),
        to: {name: 'OtherGoals'},
        exact: true
      }],
      loading: true,
      goalTables,
      goalStatus
    };
  },
  computed: mapGetters(['goals', 'currentUser']),
  methods: {
    ...mapActions(['getGoals']),
    updateOptions(options) {
      this.loading = true;
      this.getGoals({
        ...options,
        filterBy: {
          ...options.filterBy,
          status: this.goalStatus.APPROVED,
          otherGoals: this.currentUser.id
        }
      }).then(() => {
        this.loading = false;
      });
    }
  }

};
</script>
